export enum FeatureFlagEnum {

  AAD_SYNC_AGENTS = 'web-aad-sync-agents',
  SESSION_VERIFICATION = 'web-auth-session-verification',
  DIGITAL_ONBOARDING_ADMIN_PANEL = 'web-digital-onboarding-admin-panel',
  INSTANCES_REASSIGNMENT = 'web-instances-reassignment',
  PRODUCTS_FORMS_ADMIN = 'web-products-forms-admin',
  AUTOMATION_MODE = 'ninja-project',
  ACCEPTANCES_REPORT = 'web-acceptances-report',
}
